<template>
  <div class="position-relative overflow-hidden" style="background-color:#fafcfe">
    <div class="container space-top-3">
      <div class="w-md-85 w-lg-75 mx-auto text-center">
        <h1>Create Your Own API Developer Portal</h1>
        <p class="lead mb-4">Utilize FabriXAPI to construct your branded API Developer Portal, encompassing all essential functionalities and features.</p>
        <a class="btn btn-primary btn-wide transition-3d-hover" href="https://provider-portal.fabrixapi.com/" target="_blank">Start for Free <i class="far fa-arrow-right ml-1" /></a>
      </div>
    </div>
    <div class="position-relative mt-3 mt-md-0">
      <img class="img-fluid" src="@/assets/svg/illustrations/portal-generator-hero.svg" alt="OpenAPIHub Developer Portal">
    </div>
  </div>

</template>

<script>
export default {
  name: 'Hero'
}
</script>

<style lang="scss" scoped>
.modal-header {
  border-bottom: 0;
}
</style>
