<template>
  <div id="showcases">
    <div class="space-2" :style="{ background: `url(${require(`@/assets/svg/components/abstract-shapes-9-1-01.svg`)}) center no-repeat`}">
      <div class="container">
        <div class="w-md-85 w-lg-65 text-center mx-md-auto">
          <span class="badge badge-primary mb-3">New Themes Available</span>
          <h2>Style Up Your Portal</h2>
          <p>Make your API Portals stand out with our new themes.</p>
        </div>
      </div>
      <!-- eslint-disable -->
      <div class="position-relative">
        <div class="space-1 space-md-2">
          <Slick ref="showcasesCarousel" :options="slickOptions" data-aos="fade">

          <div class="px-2 mb-3 mt-6">
            <a class="card card-frame h-100 shadow transition-3d-hover" href="https://provider-portal.fabrixapi.com/" target="_blank">
                <img class="card-img-top" src="@/assets/img/portal-generator-showcases/boxed-theme.jpg">
                <div class="card-body border-top py-3">
                  <div class="text-center">
                    <span class="font-weight-bold">Build Now <i class="fa fa-angle-right ml-1"></i></span>
                  </div>
                </div>
            </a>
          </div>


            <div class="px-2 mb-3 mt-2">
              <a class="card card-frame h-100 shadow transition-3d-hover" href="https://provider-portal.fabrixapi.com/" target="_blank">
                <img class="card-img-top" src="@/assets/img/portal-generator-showcases/classic-theme.jpg">
                <div class="card-body border-top py-3">
                  <div class="text-center">
                    <span class="font-weight-bold">Build Now <i class="fa fa-angle-right ml-1"></i></span>
                  </div>
                </div>
              </a>
            </div>

             <div class="px-2 mb-3 mt-11">
              <a class="card card-frame h-100 shadow transition-3d-hover" href="https://provider-portal.fabrixapi.com/" target="_blank">
                <img class="card-img-top" src="@/assets/img/portal-generator-showcases/standard-theme.jpg">
                <div class="card-body border-top py-3">
                  <div class="text-center">
                    <span class="font-weight-bold">Build Now <i class="fa fa-angle-right ml-1"></i></span>
                  </div>
                </div>
              </a>
              </div>
            
           

            <div class="px-2 mb-3 mt-8">
              <a class="card card-frame h-100 shadow transition-3d-hover" href="https://provider-portal.fabrixapi.com/" target="_blank">
                <img class="card-img-top" src="@/assets/img/portal-generator-showcases/modern-theme.jpg">
                <div class="card-body border-top py-3">
                  <div class="text-center">
                    <span class="font-weight-bold">Build Now <i class="fa fa-angle-right ml-1"></i></span>
                  </div>
                </div>
              </a>
            </div>

            <div class="px-2 mb-3 mt-1">
              <a class="card card-frame h-100 shadow transition-3d-hover" href="https://provider-portal.fabrixapi.com/" target="_blank">
                <img class="card-img-top" src="@/assets/img/portal-generator-showcases/gallery-theme.jpg">
                <div class="card-body border-top py-3">
                  <div class="text-center">
                    <span class="font-weight-bold">Build Now <i class="fa fa-angle-right ml-1"></i></span>
                  </div>
                </div>
              </a>
            </div>
            
          </Slick>
        </div>
        <div class="w-100 w-md-65 bg-light position-absolute top-0 right-0 bottom-0 rounded-left z-index-n1" />
      </div>
			<!-- eslint-enable -->
    </div>
  </div>
</template>

<script>
import Slick from 'vue-slick'

export default {
  name: 'Showcases',
  components: {
    Slick
  },
  data() {
    return {
      slickOptions: {
        // speed: 6000,
        autoplay: true,
        infinite: true,
        autoplaySpeed: 2000,
        // cssEase: 'linear',
        slidesToShow: 3,
        slidesToScroll: 0,
        centerMode: true,
        centerPadding: '150px',
        prevArrow: '<span class="fa fa-arrow-left slick-arrow slick-arrow-primary-white slick-arrow-left slick-arrow-centered-y shadow-soft rounded-circle ml-n3 d-none" />',
        nextArrow: '<span class="fa fa-arrow-right slick-arrow slick-arrow-primary-white slick-arrow-right slick-arrow-centered-y shadow-soft rounded-circle mr-n3 d-none" />',
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 2,
              centerPadding: '150px'
            }
          },
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 2,
              centerPadding: '120px'
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
              centerPadding: '80px'
            }
          },
          {
            breakpoint: 554,
            settings: {
              slidesToShow: 1,
              centerPadding: '50px'
            }
          }
        ]
      }
    }
  },
  beforeUpdate() {
    if (this.$refs.showcasesCarousel) {
      this.$refs.showcasesCarousel.destroy()
    }
  },
  updated() {
    this.$nextTick(function() {
      if (this.$refs.showcasesCarousel) {
        this.$refs.showcasesCarousel.create(this.slickOptions)
      }
    })
  }
}
</script>

<style lang="scss" scoped>
@import '~slick-carousel/slick/slick.css';
</style>
