<template>
  <div id="how-it-works" class="position-relative overflow-hidden">
    <div class="container space-2">
      <div class="w-md-85 w-lg-75 text-center mx-md-auto mb-5">
        <span class="d-block small font-weight-bold text-cap mb-2">How It Works</span>
        <h2>Generate API Portal with a Few Clicks</h2>
        <p>The powerful API portal builder quickly generates beautiful branded Developer Portals in minutes. FabriXAPI allows you to make changes to your API Portal as much as you want at any time.</p>
      </div>

      <!-- Video -->
      <div class="w-md-65 w-lg-50 mx-auto my-7 mb-md-5">
        <div class="card shadow overflow-hidden mx-lg-auto bg-light transition-3d-hover">
          <a href="javascript:;" data-toggle="modal" data-target="#videoModal" class="video-player">
            <img class="img-fluid video-player-preview" src="@/assets/img/academy/fabrix-api-portal-arrow.jpg">
            <!-- <div class="video-player-btn video-player-centered">
              <span class="video-player-icon video-player-icon-pill">
                <i class="fas fa-play-circle mr-2 font-size-2" /> {{ $t('OW01HomePage.HeroSection.WatchButton') }}
              </span>
            </div> -->
            <div class="embed-responsive embed-responsive-16by9">
              <div id="oahVideoIframe" />
            </div>
          </a>
        </div>
        <div id="videoModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="videoModalTitle" aria-hidden="true">
          <div class="modal-xl modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 id="videoModalTitle" class="modal-title" />
                <button type="button" class="btn btn-xs btn-icon btn-soft-secondary" data-dismiss="modal" aria-label="Close">
                  <svg aria-hidden="true" width="10" height="10" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                    <path fill="currentColor" d="M11.5,9.5l5-5c0.2-0.2,0.2-0.6-0.1-0.9l-1-1c-0.3-0.3-0.7-0.3-0.9-0.1l-5,5l-5-5C4.3,2.3,3.9,2.4,3.6,2.6l-1,1 C2.4,3.9,2.3,4.3,2.5,4.5l5,5l-5,5c-0.2,0.2-0.2,0.6,0.1,0.9l1,1c0.3,0.3,0.7,0.3,0.9,0.1l5-5l5,5c0.2,0.2,0.6,0.2,0.9-0.1l1-1 c0.3-0.3,0.3-0.7,0.1-0.9L11.5,9.5z" />
                  </svg>
                </button>
              </div>
              <div class="modal-body p-2 px-sm-4 pt-0 pb-4">
                <div style="padding:52% 0 0 0;position:relative;">
                  <iframe src="https://www.youtube.com/embed/zf1mdUovbH8?autoplay=1&mute=1&loop=1" frameborder="0" allow="autoplay; fullscreen; loop;" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="FabriXAPI - What is API Portal" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row align-items-center mb-9">
        <div class="col-12 col-md-6 mb-2 mb-md-0">
          <figure class="max-w-8rem mb-3">
            <img class="img-fluid" src="@/assets/svg/illustrations/lego-1.svg">
          </figure>
          <h3 class="mb-3">Upload API Project Files</h3>
          <p>FabriXAPI supports both OpenAPI Specification (OAS) and Markdown (MD) file formats. Upload them in our online editor or on our CI/CD pipeline; we'll take care everything else! The API Portal will be generated for you in minutes.</p>
        </div>
        <div class="col-12 col-md-6">
          <div class="w-80 w-md-100 w-lg-75 mx-auto">
            <img class="img-fluid" src="@/assets/svg/illustrations/upload-file.svg" alt="Upload Swagger File">
          </div>
        </div>
      </div>

      <div class="row align-items-center mb-9">
        <div class="col-12 col-md-6 mb-2 mb-md-0 order-md-2">
          <figure class="max-w-8rem mb-3">
            <img class="img-fluid" src="@/assets/svg/illustrations/lego-2.svg">
          </figure>
          <h3 class="mb-3">Choose Your Theme & Access Rights</h3>
          <p>With our easy-to-use portal editor, you can update the content of your Developer Portal instantly. You can also choose from a variety of different layouts and colors that will match any brand! Also, don't forget the access rights control to determine who can access your API Portal.</p>
          <!-- <router-link
            :to="{ path: '/portal-generator/coming-soon'}"
            class="btn btn-primary transition-3d-hover"
          >Notify Me When Ready</router-link> -->
        </div>
        <div class="col-12 col-md-6 order-md-1">
          <div class="w-80 w-md-100 w-lg-75 mx-auto">
            <img class="img-fluid" src="@/assets/svg/illustrations/choose-colour.svg" alt="Choose Your Own Brand Colour">
          </div>
        </div>
      </div>

      <div class="row align-items-center">
        <div class="col-12 col-md-6 mb-2 mb-md-0">
          <figure class="max-w-8rem mb-3">
            <img class="img-fluid" src="@/assets/svg/illustrations/lego-3.svg">
          </figure>
          <h3 class="mb-3">Review & Publish</h3>
          <p>Once all the necessary configurations are in place for your API Portal, you have the flexibility to choose between making it publicly accessible or password protected. To grant access to your developers, you can conveniently send invitations, allowing them to gain entry to your API Portal.</p>
        </div>
        <div class="col-12 col-md-6">
          <div class="w-80 w-md-100 w-lg-75 mx-auto">
            <img class="img-fluid" src="@/assets/svg/illustrations/create-launch.svg" alt="Create and Launch Quickly">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'bootstrap'
const $ = require('jquery')

export default {
  name: 'HowItWorks',
  mounted() {
    $('#videoModal').on('hidden.bs.modal', function(e) {
      $('#videoModal iframe').attr('src', $('#videoModal iframe').attr('src'))
    })
  }
}
</script>

<style lang="scss" scoped>
.modal-header {
  border-bottom: 0;
}
</style>
