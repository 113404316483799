<template>
  <div class="container space-1">
    <div class="rounded overflow-hidden space-top-2 space-top-lg-0 px-3 px-md-8 pr-lg-0 shadow" style="background-color:#EAF4FD">
      <div class="row justify-content-lg-between align-items-lg-center no-gutters">
        <div class="col-lg-6 space-lg-1 text-center text-lg-left">
          <h2 class="mb-3">Why API Developer Portal?</h2>
          <p class="mb-4">Developer portals provide a single point of entry for developers to learn about and use APIs, while also helping businesses to build and manage their API programs. A successful Developer Portal can increase adoption, retention, and revenue opportunities through better customer experience.</p>
          <a id="ga-OW02-05-03" href="https://blog.openapihub.com/en-us/what-is-an-api-portal-and-why-it-matters/?_gl=1*29rl5d*_gcl_au*OTAyNDU1OTUxLjE2OTQ0MDAwNDA." class="btn btn-primary transition-3d-hover" target="_blank">Learn More about API Developer Portal</a>
        </div>
        <div class="col-lg-6 space-top-1 ml-auto">
          <div class="w-80 mx-auto">
            <img class="img-fluid" src="@/assets/svg/illustrations/build-portal.svg">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CTA'
}
</script>

<style lang="scss" scoped>
</style>
